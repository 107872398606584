import { Space } from '@mantine/core';
import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet';
import Header from '@/components/Header/Header';
import Footer from '@/components/Footer/Footer';
import Related from '@/components/Featured/Related';
import EditBio from '@/components/Profiles/Edit';
import { getProfile } from '@/queries';
import { useLocation } from 'react-router-dom';
import ScrollToTop from '@/components/Effects/ScrollToTop';

export default () => {
    const location = useLocation();
    const { shortUrl } = location.state || {};
    const { data, isLoading } = useQuery(['getProfile', { id: shortUrl }], getProfile, {
        retry: false
    });

    return (
        <>
        <Helmet>
            <title>{`Suggest update for ${data?.name} | They Will Kill You`}</title>
        </Helmet>
        <ScrollToTop />
        <Header />
        <EditBio data={data} />
        <Related title="Related Profiles" shortUrl={data?.shortUrl || ''} />
        <Space h="xl" />
        <Footer />
        </>
    );
};
