import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space } from '@mantine/core';
import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet';
import Header from '@/components/Header/Header';
import Footer from '@/components/Footer/Footer';
import ProfileSummary from '@/components/ProfileSummary/ProfileSummary';
import Related from '@/components/Featured/Related';
import ProfileContent from '@/components/ProfileContent/ProfileContent';
import { getProfile } from '@/queries';
import { JsonLdSchema } from '@/types';
import NotFoundPage from '@/pages/Error.page';

export default () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, isLoading, isError } = useQuery(['getProfile', { id }], getProfile, {
        retry: false
    });

    useEffect(() => {
        const newUsername = id?.toLowerCase().replace('_', '-');
        if (id !== newUsername) {
            navigate(`/profile/${newUsername}`, { replace: true });
        }
    }, [id, navigate]);

    if (isError) {
        return <NotFoundPage />;
    }

    const jsonLd: JsonLdSchema = {
        "@context": "https://schema.org",
        "@type": "Person",
        identifier: data?.shortUrl || '',
        name: data?.markup?.name || '',
        alternateName: data?.markup?.alternateName || null,
        nationality: {
        "@type": "Country",
        name: typeof data?.markup?.nationality === 'string'
            ? data?.markup?.nationality
            : 'Unknown',
        },
        description: data?.markup?.description || '',
        knowsAbout: Array.isArray(data?.markup?.knowsAbout)
        ? data.markup.knowsAbout
        : [],
        subjectOf: {
        "@type": "Thing",
        name: typeof data?.markup?.subjectOf === 'object' && data?.markup?.subjectOf?.name
            ? data?.markup?.subjectOf?.name
            : 'Unknown',
        },
        numberOfVictims: data?.markup?.numberOfVictims || 'Unknown',
        image: data?.markup?.image || [],
    };

    return (
        <>
        <Helmet>
            <title>{isLoading ? 'Loading...' : `The Story of ${data?.type} ${data?.name} | They Will Kill You`}</title>
            <script type="application/ld+json">
            {JSON.stringify(jsonLd)}
            </script>
            <meta name="description" content={jsonLd.description} />
        </Helmet>
        <Header />
        <ProfileSummary isLoading={isLoading} data={data} />
        <ProfileContent isLoading={isLoading} data={data} />
        <Related title="Related Profiles" shortUrl={data?.shortUrl || ''} />
        <Space h="xl" />
        <Footer />
        </>
    );
};
