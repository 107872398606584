import { Button, Group, Burger, Container, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  FaFacebook,
  FaYoutube,
  FaTiktok,
  FaInstagram,
  FaStore,
} from 'react-icons/fa';
import { useState } from 'react';
import classes from './Header.module.css';
import SearchAuto from '@/components/SearchAuto/SearchAuto';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.svg';

export default () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [opened, setOpened] = useState(false);
    const handleBurgerClick = () => setOpened((prev) => !prev);

    return (
        <header className={classes.header}>
            <div className={classes.inner}>
                <div className={classes.topRow}>
                    <Link to="/" className={classes.logo}>
                        <img src={Logo} alt="They Will Kill You Logo" />
                    </Link>
                    <Burger
                        opened={opened}
                        onClick={handleBurgerClick}
                        size="sm"
                        className={classes.burger}
                        color="white"
                    />
                </div>
                <div className={classes['auto-wrapper']}>
                    <SearchAuto
                        onOptionSubmit={(value: string) => navigate(`/profile/${value}`)}
                        onChange={setSearch}
                        onKeyDown={() => navigate(`/search/${search}`)}
                    />
                </div>
                <Group gap={5} visibleFrom="md">
                    <Flex gap="md">
                        <Link to="https://shop.theywillkillyou.com" target="_blank">
                            <FaStore color="white" size="24px" />
                        </Link>
                        <Link to="https://www.facebook.com/theywillkillyoufb" target="_blank">
                            <FaFacebook color="white" size="24px" />
                        </Link>
                        <Link to="https://www.youtube.com/@theywillkillyou" target="_blank">
                            <FaYoutube color="white" size="24px" />
                        </Link>
                        <Link to="https://www.tiktok.com/@theywillkillyou_tt" target="_blank">
                            <FaTiktok color="white" size="24px" />
                        </Link>
                        <Link to="https://www.instagram.com/theywillkillyou" target="_blank">
                            <FaInstagram color="white" size="24px" />
                        </Link>
                    </Flex>
                </Group>
                <div className={`${classes.mobileMenu} ${opened ? classes.open : ''}`}>
                    <Flex direction="column" gap="md">
                        <Link to="https://shop.theywillkillyou.com" target="_blank">
                            <FaStore color="white" size="24px" />
                        </Link>
                        <Link to="https://www.facebook.com/theywillkillyoufb" target="_blank">
                            <FaFacebook color="white" size="24px" />
                        </Link>
                        <Link to="https://www.youtube.com/@theywillkillyou" target="_blank">
                            <FaYoutube color="white" size="24px" />
                        </Link>
                        <Link to="https://www.tiktok.com/@theywillkillyou_tt" target="_blank">
                            <FaTiktok color="white" size="24px" />
                        </Link>
                        <Link to="https://www.instagram.com/theywillkillyou" target="_blank">
                            <FaInstagram color="white" size="24px" />
                        </Link>
                    </Flex>
                </div>
            </div>
        </header>
    );
};